.step4MainContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  .left {
    width: 60px;
    height: 60px;
    .avatorBox {
      width: 60px;
      height: 60px;
      border-radius: 6px;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.06);
      .avator {
        border-radius: 6px;
        background: #fff;
        width: 60px;
        height: 60px;
      }
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    flex: 1;
    .name {
      color: #4d4d4d;
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: 400;
    }
    .dialog {
      border-radius: 0px 24px 24px 24px;
      background: #fafafa;
      border: 1px solid #f5f5f5;
      color: #0a0a0a;
      > span {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
  .link {
    text-decoration: none;
    color: #cd292a;
    cursor: pointer;
  }
}
