@import '@/styles/viewport';

.mask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2px);
}

.fade {
  opacity: 0;
}

.fade-active {
  opacity: 1;
  transition: opacity 0.3s;
}

.fade-out {
  opacity: 1;
}

.fade-out-active {
  opacity: 0;
  transition: opacity 0.3s;
}

.panel {
  position: fixed;
  left: 0;
  background: #ffffff;
  z-index: 1000;
  max-width: vw(375);
  margin-left: calc(50%);
  transform: translate(-50%, 0);
  width: 100%;
  box-sizing: border-box;

  &-top {
    top: 0;
    border-radius: 0 0 vw(20) vw(20);
  }
  &-bottom {
    border-radius: vw(20) vw(20) 0 0;
    bottom: 0;
  }
}

.title {
  color: #1b1f4d;
  position: relative;
  padding: vw(20) vw(49) vw(20) vw(16);
  font-size: vw(18);
  font-weight: 600;
  line-height: vw(22);
}

.closeImg {
  position: absolute;
  right: vw(17);
  top: vw(20);
  width: vw(20);
  height: vw(20);
}
.slide-bottom-in {
  transform: translate(-50%, 100%);
}

.slide-bottom-in-active {
  transform: translate(-50%, 0%);

  transition: transform 0.3s;
}

.slide-bottom-out-active {
  transform: translate(-50%, 100%);
  transition: transform 0.3s;
}

.slide-top-in {
  transform: translate(-50%, -100%);
}

.slide-top-in-active {
  transform: translate(-50%, 0%);

  transition: transform 0.3s;
}

.slide-top-out-active {
  transform: translate(-50%, -100%);
  transition: transform 0.3s;
}

.topDistance {
  top: vw(10) !important;
}
