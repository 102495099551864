@import "@/styles/viewport";

.container {
  height: auto!important;
  border-radius: vw(16) vw(16) 0 0;
  background: #F5F5FC!important;
  padding: 16px 20px 52px;
  box-sizing: border-box;
  
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: vw(28);
    
    .title {
      font-size: vw(18);
      font-weight: 700;
      color: #1B1F4D;
    }
    
    .close {
      width: vw(16);
      height: vw(16);
      background: url("~@public/imgs/mobile/entry-line/close_drawer.svg") no-repeat;
    }
  }
  
  .list {

    .icon {
      width: vw(16);
      height: vw(16);
      background-size: cover!important;
    }
    
    .item {
      height: vw(56);
      border-radius: vw(10);
      background: #ffffff;
      display: flex;
      padding: vw(17) vw(20);
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      margin-bottom: vw(10);
      font-size: vw(14);
      font-weight: 400;
      border: none;
      color: #484C70;
      
      .item-left {
        display: flex;

        .logoutIcon {
          @extend .icon;
          background: url("~@public/imgs/mobile/entry-line/home-popup-logout.svg") no-repeat;
          margin-right: vw(6);
        }
      }
       
      &.act {
        font-weight: 500;
        color: #1B1F4D;
        border: 1px #FF5353 solid;
      }
      
      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      .arrow {
        @extend .icon;
        background: url("~@public/imgs/mobile/entry-line/right-arrow.svg") no-repeat;
      }
    }
  }
}