@import '@/styles/viewport';

.header-wrapper {
  box-sizing: border-box;
  width: 100%;
  padding: 0 vw(16);

  background: #fff;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: vw(48);

    .logo {
      width: vw(62);
      height: vw(21);

      background: url('~@public/imgs/mobile/home-header-black-logo-v1.png') no-repeat;
      background-size: contain;
    }

    .right {
      display: flex;

      .dropdown {
        position: relative;

        display: flex;
        align-items: center;

        font-size: vw(13);

        .earth {
          width: vw(16);
          height: vw(16);
          margin-right: vw(6);

          background: url('~@public/imgs/mobile/entry-line/home-header-earth.svg') no-repeat;
          background-size: cover;
        }

        .down_arrow {
          width: vw(10);
          height: vw(10);
          margin-right: vw(16);
          margin-left: vw(6);

          background: url('~@public/imgs/mobile/entry-line/home-header-down-arrow.svg') no-repeat;
          background-size: cover;
        }

        .lang_list {
          position: absolute;
          z-index: 1;
          top: vw(20);
          left: 0;

          box-sizing: border-box;
          width: 100%;
          padding: vw(16);

          background: #04befe;

          .lang_item {
            margin-bottom: vw(6);

            &:nth-last-child(1) {
              margin: 0;
            }
          }
        }
      }

      .menu {
        width: vw(20);
        height: vw(20);
        margin-left: vw(16);

        background: url('~@public/imgs/mobile/entry-line/home-header-menu.svg') no-repeat;
        background-size: cover;
      }
    }
  }
}
